<template>
  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <c-card title="유해위험기계기구 기본정보" :collapsed="true" class="cardClassDetailInfo">
        <template slot="card-detail">
          <div v-if="editable && equipInfo.plantNm" class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
            <c-label-text title="사업장" :value="equipInfo.plantNm"></c-label-text>
          </div>
          <div v-if="editable && equipInfo.itemNo" class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
            <c-label-text title="기계분류" :value="equipInfo.itemNo"></c-label-text>
          </div>
          <div v-if="editable && equipInfo.machineryName" class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
            <c-label-text title="기계명" :value="equipInfo.machineryName"></c-label-text>
          </div>
          <div v-if="editable && equipInfo.machineryClassificationName" class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
            <c-label-text title="item No" :value="equipInfo.machineryClassificationName"></c-label-text>
          </div>
          <div v-if="editable && equipInfo.departmentDeptName" class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
            <c-label-text title="담당부서" :value="equipInfo.departmentDeptName"></c-label-text>
          </div>
          <div v-if="editable && equipInfo.departmentUserName" class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
            <c-label-text title="담당자" :value="equipInfo.departmentUserName"></c-label-text>
          </div>
          <div v-if="editable && equipInfo.emplacementMonth" class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
            <c-label-text title="설치년월" :value="equipInfo.emplacementMonth"></c-label-text>
          </div>
          <div v-if="editable && equipInfo.gradeName" class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
            <c-label-text title="검사등급" :value="equipInfo.gradeName"></c-label-text>
          </div>
          <div v-if="editable && equipInfo.inspectionSubjectFlagName" class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
            <c-label-text title="검사대상" :value="equipInfo.inspectionSubjectFlagName"></c-label-text>
          </div>
          <div v-if="editable && equipInfo.inspectionKindName" class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
            <c-label-text title="검사종류" :value="equipInfo.inspectionKindName"></c-label-text>
          </div>
          <div v-if="editable && equipInfo.inspectionCycleName" class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
            <c-label-text title="검사주기" :value="equipInfo.inspectionCycleName"></c-label-text>
          </div>
        </template>
      </c-card>
      <c-table
        ref="table"
        title="유해위험기계기구 검사이력"
        tableId="hazardEquipmentHis"
        :columns="grid.columns"
        :data="tabParam.hazardEquipmentHistoryModels"
        :usePaging="false"
        :merge="grid.merge"
        selection="multiple"
        rowKey="inspectionNo"
      >
        <template slot="table-button">
          <q-btn-group outline >
            <c-btn v-if="editable && tabParam.inspectionSubjectFlag === 'Y'" label="추가" :showLoading="false"  icon="add" @btnClicked="addItem" />
            <c-btn
                v-if="editable && tabParam.inspectionSubjectFlag === 'Y' && tabParam.hazardEquipmentHistoryModels.length > 0"
                :url="insertUrl"
                :isSubmit="isSave"
                :param="tabParam.hazardEquipmentHistoryModels"
                mappingType="POST"
                label="저장"
                icon="save"
                @beforeAction="saveHistory"
                @btnCallback="saveCallback" />
            <c-btn v-if="editable && tabParam.inspectionSubjectFlag === 'Y' && tabParam.hazardEquipmentHistoryModels.length > 0" label="삭제" :showLoading="false" icon="remove" @btnClicked="removeItem" />
            <q-btn
              icon="help"
              color="deep-purple-6"
              text-color="white"
              class="custom-btn"
              align="center"
              round>
              <q-tooltip anchor="bottom left" self="top left">
                <div class="tooltipCustomTop">
                  버튼설명
                </div>
                <div class="tooltipCustom">
                ④ 검사대상이 대상인 경우에만 등록 가능합니다. <br>
                </div>
              </q-tooltip>
            </q-btn>
          </q-btn-group>
        </template>
        <template v-slot:customArea="{ props }">
            <template>
              <q-btn
                round unelevated
                size="6px"
                color="amber"
                icon="folder"
                @click="rowClick2(props.row, props.pageIndex)" />
            </template>
            <template>
              <q-btn
                round unelevated
                size="6px"
                color="blue"
                icon="download"
                @click="rowClick2(props.row, props.pageIndex)" />
            </template>
          </template>
      </c-table>
    </div>
  </div>
</template>
<script>
import { v4 as uuidv4 } from 'uuid';
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'hazard-equipment-history',
  props: {
    hhmHazardousMachineryId: {
      type: String,
      default: function() {
        return '';
      },
    },
    tabParam: {
      type: Object,
      default: () => ({
        hazardEquipmentHistoryModels: []
      }),
    },
  },
  data() {
    return {
      editable: true,
      equipInfo: {
        plantNm: '',
        machineryClassificationName: '',
        itemNo: '',
        emplacementMonth: '',
        inspectionSubjectFlagName: '',
        inspectionCycleName: '',
        gradeName: '',
        resultName: '',
        departmentDeptName: '',
        departmentUserName: '',
        inspectionKindName: '',
      },
      grid: {
        columns: [
          {
            required: true,
            name: "inspectionDate",
            field: "inspectionDate",
            label: "검사일자",
            style: 'width:120px',
            align: "center",
            type: 'date',
            sortable: false,
          },
          {
            name: "departmentDeptName",
            field: "departmentDeptName",
            label: "주관부서",
            style: 'width:120px',
            align: "center",
            type: 'dept',
            sortable: false,
            deptCd: 'departmentDeptCd',
          },
          {
            name: "inspectionAgency",
            field: "inspectionAgency",
            label: "기관",
            style: 'width:120px',
            align: "center",
            type: 'text',
            sortable: false,
          },
          {
            required: true,
            name: "inspectionResultCd",
            field: "inspectionResultCd",
            label: "검사결과",
            style: 'width:100px',
            type: 'select',
            comboItems: [
              { code: 'HRC000005', codeName: '합격' },
              { code: 'HRC000010', codeName: '불합격' },
            ],
            align: "center",
            sortable: false,
          },
          {
            name: "inspectionResultDetail",
            field: "inspectionResultDetail",
            label: "검사결과 상세",
            style: 'width:350px',
            type: 'textarea',
            align: "center",
            sortable: false,
          },
          {
            name: "fileNm",
            field: "fileNm",
            style: 'width:100px',
            label: "파일첨부",
            type: 'custom',
            align: "center",
            sortable: false,
          },
        ],
        height: '400px',
        data: [],
      },
      isSave: false,
      checkCdItems: [], // 검사결과
      getUrl: '',
      getStdUrl: '',
      insertUrl: '',
      deleteUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    Object.assign(this.$data, this.$options.data());
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
  },
  watch: {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.insertUrl = transactionConfig.sop.hhm.hazard.hazardHistory.insert.url;
      this.deleteUrl = transactionConfig.sop.hhm.hazard.hazardHistory.delete.url;
      this.getStdUrl = selectConfig.sop.hhm.hazard.check.get.url;
      this.getUrl = selectConfig.sop.hhm.hazard.hazardHistory.get.url;
      this.getStandardInfo();
      // this.getDetail();
    },
    getStandardInfo() {
      if (this.hhmHazardousMachineryId) {
        this.$http.url = this.$format(this.getStdUrl, this.hhmHazardousMachineryId);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.equipInfo = this.$_.clone(_result.data);
          this.updateMode = true;
        },);
      }
    },
    addItem() {
      this.tabParam.hazardEquipmentHistoryModels.splice(0, 0, {
        hhmHazardousMachineryId: this.hhmHazardousMachineryId,
        inspectionNo: uuidv4(),
        inspectionDate: '',
        departmentDeptCd: '',
        inspectionAgency: '',
        inspectionResultCd: null,
        inspectionResultDetail: '',
        editFlag: 'C',
        fileNm: '',
      })
    },
    removeItem() {
      let selectData = this.$refs['table'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '하나 이상 선택하세요.', // 하나 이상 선택하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: '삭제하시겠습니까?',
        // TODO : 필요시 추가하세요.
        type: 'warning', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.deleteUrl;
          this.$http.type = 'DELETE';
          this.$http.param = {
            data: Object.values(selectData)
          }
          this.$http.request(() => {
            this.$_.forEach(selectData, item => {
              this.tabParam.hazardEquipmentHistoryModels = this.$_.reject(this.tabParam.hazardEquipmentHistoryModels, { hhmHazardousMachineryId: item.hhmHazardousMachineryId })
            })
            window.getApp.$emit('APP_REQUEST_SUCCESS');
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
      }
    },
    saveHistory() {
      let isConti = true;
      let checkItem = ['inspectionDate', 'inspectionResultCd' ]
      this.$_.forEach(this.tabParam.hazardEquipmentHistoryModels, item => {
        this.$_.forEach(checkItem, check => {
          if (!item[check]) {
            isConti = false;
            return false;
          }
        })
      });
      if (!isConti) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '필수 입력값을 입력해 주세요. [검사일자, 검사결과]',
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '저장하시겠습니까?',
          // TODO : 필요시 추가하세요.
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.isSave = !this.isSave;
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    saveCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
    },
  }
};
</script>