var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row" }, [
    _c(
      "div",
      { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" },
      [
        _c(
          "c-card",
          {
            staticClass: "cardClassDetailInfo",
            attrs: { title: "유해위험기계기구 기본정보", collapsed: true },
          },
          [
            _c("template", { slot: "card-detail" }, [
              _vm.editable && _vm.equipInfo.plantNm
                ? _c(
                    "div",
                    {
                      staticClass:
                        "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3",
                    },
                    [
                      _c("c-label-text", {
                        attrs: {
                          title: "사업장",
                          value: _vm.equipInfo.plantNm,
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.editable && _vm.equipInfo.itemNo
                ? _c(
                    "div",
                    {
                      staticClass:
                        "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3",
                    },
                    [
                      _c("c-label-text", {
                        attrs: {
                          title: "기계분류",
                          value: _vm.equipInfo.itemNo,
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.editable && _vm.equipInfo.machineryName
                ? _c(
                    "div",
                    {
                      staticClass:
                        "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3",
                    },
                    [
                      _c("c-label-text", {
                        attrs: {
                          title: "기계명",
                          value: _vm.equipInfo.machineryName,
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.editable && _vm.equipInfo.machineryClassificationName
                ? _c(
                    "div",
                    {
                      staticClass:
                        "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3",
                    },
                    [
                      _c("c-label-text", {
                        attrs: {
                          title: "item No",
                          value: _vm.equipInfo.machineryClassificationName,
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.editable && _vm.equipInfo.departmentDeptName
                ? _c(
                    "div",
                    {
                      staticClass:
                        "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3",
                    },
                    [
                      _c("c-label-text", {
                        attrs: {
                          title: "담당부서",
                          value: _vm.equipInfo.departmentDeptName,
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.editable && _vm.equipInfo.departmentUserName
                ? _c(
                    "div",
                    {
                      staticClass:
                        "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3",
                    },
                    [
                      _c("c-label-text", {
                        attrs: {
                          title: "담당자",
                          value: _vm.equipInfo.departmentUserName,
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.editable && _vm.equipInfo.emplacementMonth
                ? _c(
                    "div",
                    {
                      staticClass:
                        "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3",
                    },
                    [
                      _c("c-label-text", {
                        attrs: {
                          title: "설치년월",
                          value: _vm.equipInfo.emplacementMonth,
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.editable && _vm.equipInfo.gradeName
                ? _c(
                    "div",
                    {
                      staticClass:
                        "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3",
                    },
                    [
                      _c("c-label-text", {
                        attrs: {
                          title: "검사등급",
                          value: _vm.equipInfo.gradeName,
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.editable && _vm.equipInfo.inspectionSubjectFlagName
                ? _c(
                    "div",
                    {
                      staticClass:
                        "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3",
                    },
                    [
                      _c("c-label-text", {
                        attrs: {
                          title: "검사대상",
                          value: _vm.equipInfo.inspectionSubjectFlagName,
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.editable && _vm.equipInfo.inspectionKindName
                ? _c(
                    "div",
                    {
                      staticClass:
                        "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3",
                    },
                    [
                      _c("c-label-text", {
                        attrs: {
                          title: "검사종류",
                          value: _vm.equipInfo.inspectionKindName,
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.editable && _vm.equipInfo.inspectionCycleName
                ? _c(
                    "div",
                    {
                      staticClass:
                        "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3",
                    },
                    [
                      _c("c-label-text", {
                        attrs: {
                          title: "검사주기",
                          value: _vm.equipInfo.inspectionCycleName,
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ]),
          ],
          2
        ),
        _c(
          "c-table",
          {
            ref: "table",
            attrs: {
              title: "유해위험기계기구 검사이력",
              tableId: "hazardEquipmentHis",
              columns: _vm.grid.columns,
              data: _vm.tabParam.hazardEquipmentHistoryModels,
              usePaging: false,
              merge: _vm.grid.merge,
              selection: "multiple",
              rowKey: "inspectionNo",
            },
            scopedSlots: _vm._u([
              {
                key: "customArea",
                fn: function ({ props }) {
                  return [
                    [
                      _c("q-btn", {
                        attrs: {
                          round: "",
                          unelevated: "",
                          size: "6px",
                          color: "amber",
                          icon: "folder",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.rowClick2(props.row, props.pageIndex)
                          },
                        },
                      }),
                    ],
                    [
                      _c("q-btn", {
                        attrs: {
                          round: "",
                          unelevated: "",
                          size: "6px",
                          color: "blue",
                          icon: "download",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.rowClick2(props.row, props.pageIndex)
                          },
                        },
                      }),
                    ],
                  ]
                },
              },
            ]),
          },
          [
            _c(
              "template",
              { slot: "table-button" },
              [
                _c(
                  "q-btn-group",
                  { attrs: { outline: "" } },
                  [
                    _vm.editable && _vm.tabParam.inspectionSubjectFlag === "Y"
                      ? _c("c-btn", {
                          attrs: {
                            label: "추가",
                            showLoading: false,
                            icon: "add",
                          },
                          on: { btnClicked: _vm.addItem },
                        })
                      : _vm._e(),
                    _vm.editable &&
                    _vm.tabParam.inspectionSubjectFlag === "Y" &&
                    _vm.tabParam.hazardEquipmentHistoryModels.length > 0
                      ? _c("c-btn", {
                          attrs: {
                            url: _vm.insertUrl,
                            isSubmit: _vm.isSave,
                            param: _vm.tabParam.hazardEquipmentHistoryModels,
                            mappingType: "POST",
                            label: "저장",
                            icon: "save",
                          },
                          on: {
                            beforeAction: _vm.saveHistory,
                            btnCallback: _vm.saveCallback,
                          },
                        })
                      : _vm._e(),
                    _vm.editable &&
                    _vm.tabParam.inspectionSubjectFlag === "Y" &&
                    _vm.tabParam.hazardEquipmentHistoryModels.length > 0
                      ? _c("c-btn", {
                          attrs: {
                            label: "삭제",
                            showLoading: false,
                            icon: "remove",
                          },
                          on: { btnClicked: _vm.removeItem },
                        })
                      : _vm._e(),
                    _c(
                      "q-btn",
                      {
                        staticClass: "custom-btn",
                        attrs: {
                          icon: "help",
                          color: "deep-purple-6",
                          "text-color": "white",
                          align: "center",
                          round: "",
                        },
                      },
                      [
                        _c(
                          "q-tooltip",
                          {
                            attrs: { anchor: "bottom left", self: "top left" },
                          },
                          [
                            _c("div", { staticClass: "tooltipCustomTop" }, [
                              _vm._v(" 버튼설명 "),
                            ]),
                            _c("div", { staticClass: "tooltipCustom" }, [
                              _vm._v(
                                " ④ 검사대상이 대상인 경우에만 등록 가능합니다. "
                              ),
                              _c("br"),
                            ]),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          2
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }